.chat-app {
    width: 100%;
    height: 100%;
    overflow-y: auto;
}

.fallback {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

:global(#chatSearchRoot) {
    & > div:first-child {
        border-bottom: none;
    }
}
