@import "../../../assets/styles/main.scss";

.modal-give-feedback {
    height: 470px;
    width: 400px;
    background: #ffffff;
    border: 1px solid #DFE3E8;
    border-radius: 16px;
    padding: 20px;
    gap: 8px;
    box-shadow: 0px 12px 24px 0px rgba(19, 22, 25, 0.4019607843);

}
.dragHandle {
    height: 75px;
    width: 100%;
    position: absolute;
}

.modal-give-feedback__header {
    font-size: 20px;
    font-weight: 800;
    line-height: 32px;
    padding: 0 5px;
}

.modal-give-feedback__body {
    max-height: 376px;
    overflow: auto;
    padding: 0 5px;
    .feedback-type-wrapper {
        display: flex;
        flex-direction: column;
        margin-top: 10px;

        .asterix {
            color:#b22424;
        }

        .satisfaction-empty-warning {
            span {
                font-size: 14px;
                color:#b22424;
            }
        }
        span {
            margin-bottom: 5px;
            font-weight: 600;
            font-size: 15px;
        }

        .score-options {
            display: flex;
            gap: 25px;

            .score-button {
                height: 42px;
                width: 42px;
                border: 1px solid rgba(110, 118, 128, 0.5);
                border-radius: 100px;
                background-color: #F1F4F6;
                font-size: 20px;
                line-height: 40px;
                text-align: center;
                font-weight: 500;
                padding: 0;

                &.active {
                    background-color: $zoom-blue;
                    color: #fff;
                }
            }
        }

        .lowest-highest-ratings {
            display: flex;
            width: 95%;
            margin-top:5px;
             
            span {
                font-size: 10px;
                color: grey;
            }

            .satisfaction-subtitle-right {
                margin-left: auto;
            }
        }

        .feedback-dropdown-button {
            button {
                border: 1px solid grey;
                background: #FFF;
                color: black;
                width: 100%;
                height: 30px;
                display: flex;
                align-items: center;
            }

            .chevron-down {
                margin-left: auto;
            }

            .dropdown-menu {
                width: 100%;
            }
        }

        .feedback-text-input {
            font-size: 14px;
            border-radius: 8px;
            padding: 5px;
            margin-top: 5px;
        }

        .message-length {
            margin-top: 5px;
            margin-left: auto;
            font-weight: 500;
            font-size: 13px;
            color: grey;
        }
    }
}

.give-feedback-footer-buttons {
    display: flex;
    justify-content: right;
    gap: 10px;
    margin: 10px 0px 15px 0px;
    button {
        font-weight:600;
    }
}

.technical-suppport-text {
    font-size: 14px;
    text-align: center;
    font-weight: 500;
}

.dropdown-toggle {
    &::after {
        display: none;
    }
}

.dropdown-item {
    border-radius: 8px;
}