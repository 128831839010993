.home {
    position: relative;
    width: 100%;
    height:100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

.home [data-main-body] {
    flex: 1 1 auto;
    overflow: auto; 
    micro-app-body {
        height: 100%;
    }
}